import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Loader from '../Loader'
import { useGetLocsQuery, useGetThingsQuery } from '../../Apihandlers/apidata';

function Header() {
  const [act, setAct] = useState(false);

  const openSidebar = () => {
    setAct(true);
  };

  const closeSidebar = () => {
    setAct(false);
  };

  const { data, isError, isLoading } = useGetLocsQuery();
  const { data: thingData, isError: thingError, isLoading: thingLoading } = useGetThingsQuery();

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.headermain');
      const navLinks = document.querySelectorAll('.nav-links a');
      const nav = document.querySelector('.nav-links');
      
      if (window.pageYOffset > header.clientHeight) {
        header.style.backgroundColor = '#000';
      } else {
        header.style.backgroundColor = 'transparent';
      }
      
      navLinks.forEach((link) => {
        if (window.pageYOffset > header.clientHeight) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="headermain">
      <div className="header-logo">
        <a href="/"><img src="../logo/cyj-white-logo.png" alt="logo" /></a>
        <i className="fa-solid fa-bars openbtn" onClick={openSidebar}></i>
      </div>

      <div className="nav-links">
        <a href="/">Home</a>
        <div className="dropdown">
          <p>Destinations</p>
          <div className="dropdown-content">
            <div>
              {isLoading ? (
                <p> <Loader/></p>
              ) : isError ? (
                <p>Error fetching data</p>
              ) : (
                data.map((itm) =>
                  itm.location.status === 1 ? (
                    <a key={itm.location.location_url} href={`/${itm.location.location_url}`}>
                      {itm.location.location}
                    </a>
                  ) : null
                )
              )}
            </div>
          </div>
        </div>
        <a href="/adventure_camps.php">Adventure Camps</a>
        <div className="dropdown">
          <p>Adventure Activities</p>
          <div className="dropdown-content">
            {thingLoading ? (
              <p> <Loader/></p>
            ) : thingError ? (
              <p>Error fetching data</p>
            ) : (
              thingData.map((itm) => (
                <a key={itm.things.things_url} href={itm.things.things_url}>
                  {itm.things.things}
                </a>
              ))
            )}
          </div>
        </div>
        <a href='https://www.corporateeventplanner.in/' rel="noopener noreferrer" target='blank'>Corporate Events</a>
        <a href='https://www.weddingmantras.co.in/' rel="noopener noreferrer" target='blank'>Destination Weddings</a>
        <a  href="/contact.php">Contact</a>
      </div>

      
      <div id="sidebar" className={`sidebar ${act ? 'act' : ''}`}>
        <i className="fa-solid fa-xmark" onClick={closeSidebar}></i>
        <ul>
          <li> <a href="/">Home</a></li>
          <li className="dropdown-sidebar">
            <p className="dropbtn-sidebar">Destination</p>
            <div className="dropdown-content-sidebar" id="locationDropdown">
            {isLoading ? (
                <p> <Loader/></p>
              ) : isError ? (
                <p>Error fetching data</p>
              ) : (
                data.map((itm) =>
                  itm.location.status === 1 ? (
                    <a key={itm.location.location_url} href={`/${itm.location.location_url}`}>
                      {itm.location.location}
                    </a>
                  ) : null
                )
              )}
            </div>
          </li>
          <li className="dropdown-sidebar">
            <p className="dropbtn-sidebar">Adventure Activities</p>
            <div className="dropdown-content-sidebar" id="locationDropdown">
            {thingLoading ? (
              <p> <Loader/></p>
            ) : thingError ? (
              <p>Error fetching data</p>
            ) : (
              thingData.map((itm) => (
                <a key={itm.things.things_url} href={itm.things.things_url}>
                  {itm.things.things}
                </a>
              ))
            )}
            </div>
          </li>
          <li><a href="/adventure_camps.php">Adventure Camps</a></li>
          <li><a href='https://www.corporateeventorganisers.in/'>Corporate Events</a></li>
          <li><a href='https://www.weddingmantras.co.in/'>Destination Weddings</a></li>
          <li><a href="/contact.php">Contact</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
