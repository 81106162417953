import React from 'react'
import './indexcss/Otherweb.css';
function Otherweb() {
  return (
    <div className='other-section'>  
      <p id='otherwebs'>Other</p>
      <h2> Services We Offer</h2>
      <section className='other-web-section'>

        <div className='other-containers'>
            <div className='web-image'>
                <img src='../images/corporate.jpg' alt='kanatal resorts'></img>
            </div>
            <div className='web-text'>
            <p className='webotherText'>Corporate Association, Meetings, Conferences and other professional activities or procedures needs a dynamic attention as it forms a very important part of our professional life. </p>
            <a href='https://www.corporateeventplanner.in/' rel="noopener noreferrer" aria-label="corporate event organisers">Book for Corporate Events</a>
            </div>
        </div>

        <div className='other-containers'>
            <div className='web-image'>
                <img src='../images/wedding.png' alt='kanatal resorts'></img>
            </div>
            <div className='web-text'>
            <p className='webotherText'>Wedding Mantras (A part of CYJ Events)provide one stop solution for wedding related services and at the same time work their nights off to pull together the event of your dreams.</p>
            <a href='https://www.weddingmantras.co.in/' rel="noopener noreferrer" aria-label="wedding mantras ">Book for Destination Wedding</a>
            </div>
        </div>

      </section>
    </div>
  )
}

export default Otherweb
